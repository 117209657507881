/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.35.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AppResponse
 */
export interface AppResponse {
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'packageName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppResponse
     */
    'appCategoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'img'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'shortDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'formattedDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppResponse
     */
    'minimumAge'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppResponse
     */
    'monitoredByBark'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppResponse
     */
    'neverDisable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'playStoreURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'developerSite'?: string;
    /**
     * 
     * @type {Date}
     * @memberof AppResponse
     */
    'madePublicOn'?: Date;
    /**
     * 
     * @type {number}
     * @memberof AppResponse
     */
    'downloads'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppResponse
     */
    'level'?: AppResponseLevelEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppResponse
     */
    'appDescriptionIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AppResponse
     */
    'hideForNewUsers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppResponse
     */
    'isBanned'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppResponse
     */
    'supportedWatchDevices'?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AppResponseLevelEnum {
    CoreApps = 'CoreApps',
    PinwheelApproved = 'PinwheelApproved',
    SlightlyOutOfBounds = 'SlightlyOutOfBounds',
    ViolatesGuidelines = 'ViolatesGuidelines',
    UntestedByPinwheel = 'UntestedByPinwheel'
}


